import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function ICanChange() {
  return (
    <Layout>
      <Helmet title="I Can Change | Lake Street Dive" />

      <h1>I Can Change</h1>

      <h2>Lake Street Dive</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Hate casts a long shadow</p>
          <p>I know that I lie in it</p>
          <p>And let it rule my mind from time to time</p>
        </Verse>
        <Verse>
          <p>Escaping an old battle</p>
          <p>Clings on like a vine to me</p>
          <p>Whispers dirty lies in my ear (ahh-I)</p>
        </Verse>
        <PreChorus>
          <p>We didn't start this fight</p>
          <p>And I won't let it rule my heart tonight</p>
        </PreChorus>
        <Chorus>
          <p>I can change</p>
          <p>I can change</p>
          <p>I can still change</p>
          <p>I can still change</p>
        </Chorus>
        <Verse>
          <p>Tracing an old pattern</p>
          <p>Drawing the lines from where I am, and from where I wanna be</p>
        </Verse>
        <Verse>
          <p>Forget that old adage</p>
          <p>The history continues to keep us from the world we wanna see</p>
        </Verse>
        <PreChorus>
          <p>I am scared that I won't get it right</p>
          <p>But fear won't rule my heart tonight</p>
        </PreChorus>
        <Chorus>
          <p>I can change</p>
          <p>I can change</p>
          <p>I can still change</p>
          <p>I can still change</p>
        </Chorus>
        <Chorus>
          <p>I can change</p>
          <p>I can change</p>
          <p>I can still change</p>
          <p>I can still change</p>
        </Chorus>
        <Chorus>
          <p>I can change</p>
          <p>I can change</p>
          <p>I can still change</p>
          <p>I can still change</p>
        </Chorus>
        <Chorus>
          <p>I can change</p>
          <p>I can change</p>
          <p>I can still change</p>
          <p>I can still change</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
